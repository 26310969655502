import { Button } from '@atoms/button';
import { TextButton } from '@atoms/textButton';
import { Header } from '@organisms/header';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { CalendarMonth, CalendarMonths, DatesRange } from '@utils/types/calendar';
import { ChevronLeft, ChevronRight, Informative } from 'assets/icons';
import clsx from 'clsx';
import { addMonths, eachDayOfInterval, isAfter, isBefore, isSameDay, isWithinInterval, } from 'date-fns';
import { findIndex, isEqual, debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';

const defaultDateRange: DatesRange = {
  from: null,
  to: null,
};

const MONTHS_AHEAD = 12;
const days = ['Su', 'Mon', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const generateMonthInfo = (month: Date) => {
  month.setDate(1);
  const day = month.getDay();
  const daysInMonth = new Date(
    month.getFullYear(),
    month.getMonth() + 1,
    0
  ).getDate();
  return {
    year: month.getFullYear(),
    month: month.getMonth(),
    days: [
      ...Array.from(new Array(day).keys()).map(() => ''),
      ...Array.from(new Array(daysInMonth).keys()).map((d) => d + 1),
    ],
  };
};

const generateMonthsData = () =>
  Array.from(new Array(MONTHS_AHEAD).keys()).map((month) => {
    const monthDate = addMonths(new Date(), month);
    return generateMonthInfo(monthDate);
  });

const getDefaultMonthIndex = (selectedDates: DatesRange, months: CalendarMonths) => {
  if (selectedDates.from) {
    const selectedMonthIndex = months.findIndex((m) =>
      m.month === selectedDates.from?.getMonth());
    if (selectedMonthIndex) return months[selectedMonthIndex];
  }
  return months[0];
};

type Props = {
  onDatesChange: (dates: DatesRange) => void;
  toggleCalendar: () => void;
  selectDates: () => void;
  value: DatesRange;
  required: boolean;
  /** Date ISO String array */
  disabledDates: string[];
  onDisabledDayError: () => void;
  minimumDate?: string;
  maximumDate?: string;
  renderDayContent?: (date: Date) => string | number | JSX.Element;
  error: string | null;
  actionButtonText: string;
} & BaseComponentProps;

MobileCalendar.defaultProps = {
  value: [],
  required: false,
  disabledDates: [],
  onDisabledDayError: () => {},
  renderDay: null,
  error: null,
  actionButtonText: 'Search',
};

export function MobileCalendar(props: Props) {
  const {
    containerStyle,
    onDatesChange,
    toggleCalendar,
    selectDates,
    value,
    required,
    disabledDates = [],
    onDisabledDayError,
    maximumDate,
    minimumDate,
    renderDayContent,
    actionButtonText,
    error,
  } = props;
  const months = useMemo(generateMonthsData, []);
  const today = useMemo(() => new Date(), []);
  const maxDate = useMemo(() => (maximumDate ? new Date(maximumDate) : ''), []);
  const minDate = useMemo(() => (minimumDate ? new Date(minimumDate) : ''), []);
  const isMobile = useIsMobile();
  const calendarBodyRef = useRef<HTMLDivElement>(null);
  const [selectedDates, setSelectedDates] =
    useState<DatesRange>(defaultDateRange);
  const [currentMonth, setCurrentMonth] = useState<CalendarMonth>(
    getDefaultMonthIndex(value, months)
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const clearDates = () => {
    setSelectedDates(defaultDateRange);
    onDatesChange(defaultDateRange);
  };

  const isRangeInvalid = (dates: DatesRange) => {
    const { from, to } = dates;
    if (from && to) {
      const dates = eachDayOfInterval({ start: from, end: to });
      let isInvalid = false;
      for (let date of dates) {
        isInvalid = disabledDates.includes(date.toISOString());
        if (isInvalid) {
          break;
        }
      }
      return isInvalid;
    }
    return false;
  };

  const selectDate = (date: Date) => {
    setSelectedDates((prevDates) => {
      let newDates: DatesRange = defaultDateRange;
      if (!!prevDates.from) {
        if (!!prevDates.to) {
          newDates = {
            from: date,
            to: null,
          };
        } else {
          newDates = {
            ...prevDates,
            to: date,
          };
        }
      } else {
        newDates = {
          from: date,
          to: null,
        };
      }
      if (newDates.from && newDates.to) {
        if (newDates.from.getTime() > newDates.to.getTime()) {
          newDates = {
            from: newDates.to,
            to: newDates.from,
          };
        }
      }
      if (isRangeInvalid(newDates)) {
        onDisabledDayError();
        return defaultDateRange;
      }
      onDatesChange(newDates);
      return newDates;
    });
  };

  const renderMonth = (
    { days, month, year }: CalendarMonth,
    index: number,
  ) => {
    const isDayIncluded = (date: Date) =>
      selectedDates.from && selectedDates.to
        ? isWithinInterval(date, {
          start: selectedDates.from,
          end: selectedDates.to,
        })
        : false;
    const isDayLimit = (date: Date) =>
      selectedDates.to?.toISOString() === date.toISOString() ||
      selectedDates.from?.toISOString() === date.toISOString();

    return (
      <div className={styles.month} key={index}>
        {isMobile && (
          <span className={styles.header}>
            {monthNames[month]} {year}
          </span>
        )}
        <div className={styles.days}>
          {days.map((day, idx) => {
            if (typeof day === 'number') {
              const date = new Date(year, month, day);
              const isDisabled =
                disabledDates.includes(date.toISOString()) ||
                (maxDate && isAfter(date, maxDate)) ||
                (minDate && isBefore(date, minDate));
              return (
                <div
                  onClick={() =>
                    isDisabled ? onDisabledDayError() : selectDate(date)
                  }
                  key={`${day}-${idx}`}
                  className={clsx([
                    styles.day,
                    !isDisabled && 'clickable',
                    isSameDay(today, date) && styles.today,
                    isDisabled && styles.disabled,
                    day && styles.border,
                    isDayIncluded(date) && styles.selected,
                    isDayLimit(date) && styles.limit,
                  ])}
                >
                  {renderDayContent ? renderDayContent(date) : day}
                </div>
              );
            }
            return <div key={`${day}-${idx}`} />;
          })}
        </div>
      </div>
    );
  };

  const goToPrevMonth = useCallback(() => {
    const currentMonthIndex = findIndex(months, (v) =>
      isEqual(v, currentMonth)
    );
    if (currentMonthIndex === 0) {
      return;
    }
    if (calendarBodyRef.current) {
      calendarBodyRef.current.scrollLeft -= 275;
    }
    setCurrentMonth(months[currentMonthIndex - 1]);
  }, [currentMonth, months]);

  const goToNextMonth = useCallback(() => {
    const currentMonthIndex = findIndex(months, (v) =>
      isEqual(v, currentMonth)
    );
    if (currentMonthIndex === months.length - 1) {
      return;
    }
    if (calendarBodyRef.current) {
      calendarBodyRef.current.scrollLeft += 275;
    }
    setCurrentMonth(months[currentMonthIndex + 1]);
  }, [currentMonth, months]);

  const goToMonthByMonthIndex = useCallback((monthIndex: number) => {
    if (currentMonth.month === monthIndex) return;

    if (calendarBodyRef.current) {
      calendarBodyRef.current.scrollLeft = 0;
      calendarBodyRef.current.scrollLeft += monthIndex * 275;
    }
    setCurrentMonth(months[monthIndex]);
  }, [currentMonth, months]);

  useEffect(() => {
    // Scroll to the specific month if some date range
    // is already selected when the didMount event occurs
    if (currentMonth.month !== months[0].month) {
      goToMonthByMonthIndex(months.findIndex((m) => m.month === currentMonth.month));
    }
    // eslint-disable-next-line
  }, []);

  const handleKeyboardArrows = useCallback((e: KeyboardEvent) => {
    if(["ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
      e.preventDefault();
      if(["ArrowLeft"].indexOf(e.code) > -1) {
        goToPrevMonth();
      } else {
        goToNextMonth();
      }
    }
  }, [goToNextMonth, goToPrevMonth]);

  const handleMouseWheel = useCallback((e) => {

    // YAxis scroll - mWheelUp/mWheelDown + Shift
    if (e.shiftKey) {
      if (Math.sign(e.deltaY) === 1) goToNextMonth();
      if (Math.sign(e.deltaY) === -1) goToPrevMonth();
    }

    // XAxis scroll - trackpad / touchpad
    if (e.deltaX !== 0) {
      if (Math.sign(e.deltaX) === 1) goToNextMonth();
      if (Math.sign(e.deltaX) === -1) goToPrevMonth();
    }
  }, [goToNextMonth, goToPrevMonth]);

  const debouncedMouseWheel = useMemo(
    () => debounce(handleMouseWheel, 25),
    [handleMouseWheel]);

  const onMouseWheel = useCallback(
    (e) => {
      if (e.shiftKey || e.deltaX !== 0) {
        e.preventDefault();
        e.stopPropagation();
        debouncedMouseWheel(e);
      }
    },
    [debouncedMouseWheel]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardArrows);
    window.addEventListener("mousewheel", onMouseWheel, { passive: false });
    return () => {
      window.removeEventListener("keydown", handleKeyboardArrows);
      window.removeEventListener("mousewheel", onMouseWheel);
    }
  }, [handleKeyboardArrows, onMouseWheel]);

  useEffect(() => {
    setSelectedDates(value);
  }, [value]);

  useEffect(() => {
    if (error && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [error]);

  return isMobile ? (
    <div className={clsx([styles.mobileContainer, containerStyle])}>
      <Header />
      <div className={styles.header}>
        <TextButton
          onClick={toggleCalendar}
          containerStyle={styles.backButton}
          label={
            <>
              <ChevronLeft />
              &nbsp; Select days
            </>
          }
        />
        <div className={styles.clear}>
          <TextButton
            label="Clear dates"
            onClick={clearDates}
            containerStyle={styles.clearButton}
          />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.week}>
          {days.map((day, idx) => (
            <div key={idx} className={styles.day}>
              {day}
            </div>
          ))}
        </div>
        <div className={styles.list}>{months.map(renderMonth)}</div>
      </div>
      <div className={styles.footer}>
        <Button
          label={actionButtonText}
          onClick={selectDates}
          disabled={required && (!selectedDates.to || !selectedDates.from)}
        />
      </div>
    </div>
  ) : (
    <div
      className={clsx([styles.container, containerStyle])}
      ref={containerRef}
    >
      <div className={styles.header}>
        <div onClick={goToPrevMonth} className={'clickable'}>
          <ChevronLeft />
        </div>
        <span>
          {monthNames[currentMonth.month]} {currentMonth.year}
        </span>
        <div onClick={goToNextMonth} className={'clickable'}>
          <ChevronRight />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.week}>
          {days.map((day, idx) => (
            <div key={idx} className={styles.day}>
              {day}
            </div>
          ))}
        </div>
        <div
          className={styles.list}
          ref={calendarBodyRef}
        >
          {months.map(renderMonth)}
        </div>
      </div>
      {error && (
        <div className={styles.calendarError}>
          <Informative className={styles.errorIcon} />
          <span>{error}</span>
        </div>
      )}
      <div className={styles.footer}>
        <TextButton
          label={'Clear dates'}
          onClick={clearDates}
          containerStyle={styles.footerSpan}
        />
        <TextButton
          label={'Apply'}
          onClick={selectDates}
          containerStyle={styles.footerSpan}
        />
      </div>
    </div>
  );
}
